.notification-alert {
  background-color: #ff6347;
  color: #ffffff;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 97%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9999;
  margin-right: 2rem;

  p {
    margin: 0;
    font-size: 0.9rem;
    text-align: center;
    align-self: flex-start;
    padding-left: 0.9rem;
    width: 100%;
  }

  button {
    background-color: transparent;
    color: inherit;
    text-align: center;
    border: none;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }

  button:hover {
    opacity: 0.8;
  }

  main-content {
    padding-top: 3rem;
  }
}
